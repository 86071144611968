import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Lightbox from "../components/lightBox";
import * as styles from "./index.module.css";
import Container from "../components/container";
import ContactForm from "../components/ContactForm";
import {
  FaFile,
  FaCalendarDay,
  FaPhone,
  FaUsers,
  FaUserPlus,
  FaLaptop,
  FaRegClipboard,
  FaUserTie,
} from "react-icons/fa";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Columns from "../components/columns";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";

export const query = graphql`
  {
    heroBg: file(
      relativePath: {
        eq: "questy-producent-kompleksowych-rozwiazan-dla-biznesu.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imgLaptopITel: file(relativePath: { eq: "mobilny-program-crm.png" }) {
      childImageSharp {
        gatsbyImageData(width: 800, layout: CONSTRAINED)
      }
    }
    imgHero: file(
      relativePath: { eq: "synergius-crm-program-crm-dla-firm.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 800, layout: CONSTRAINED)
      }
    }
    imgSage: file(relativePath: { eq: "integracje/integracja-symfonia.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
    imgSubiekt: file(
      relativePath: { eq: "integracje/integracja-subiekt.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
    imgCDN: file(relativePath: { eq: "integracje/integracja-optima.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
    imgWapro: file(relativePath: { eq: "integracje/integracja-wapro.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
    logoRadioSzczecin: file(
      relativePath: { eq: "rszczecin-logo-150x150.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    logoLoco: file(relativePath: { eq: "loco-logo-150x150.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    logoScapol: file(relativePath: { eq: "scapol-logo-150x150.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    modecom: file(relativePath: { eq: "modecom-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    unilever: file(relativePath: { eq: "kolor-unilever.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    interhandler: file(relativePath: { eq: "jcb-interhandler-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    pgnig: file(relativePath: { eq: "pgnig_logotyp.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    mtp: file(relativePath: { eq: "grupa-mtp-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    kompania: file(relativePath: { eq: "kompania-piwowarska-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    sab: file(relativePath: { eq: "sab-miller.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    enpol: file(relativePath: { eq: "enpol.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    dedra: file(relativePath: { eq: "kolor-dedra.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    mfo: file(relativePath: { eq: "mfo-logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    zibi: file(relativePath: { eq: "zibi.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    europart: file(relativePath: { eq: "europart.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    euromasz: file(relativePath: { eq: "euromasz-logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
    ever: file(relativePath: { eq: "kolor-ever.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    digital: file(relativePath: { eq: "dsd.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    kospel: file(relativePath: { eq: "kolor-kospel.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    petring: file(relativePath: { eq: "petring.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    aermec: file(relativePath: { eq: "aermec-logotyp.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    copy: file(relativePath: { eq: "copy-system.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    metal: file(relativePath: { eq: "metal-fach.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    axium: file(relativePath: { eq: "axium-logo-s.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    gourmet: file(relativePath: { eq: "gourmet-foods-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    pikselomania: file(relativePath: { eq: "kolor-pikselomania.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    mzuri: file(relativePath: { eq: "mzuri.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    hydrotank: file(relativePath: { eq: "hydrotank.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    yg: file(relativePath: { eq: "yg1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    logo: file(relativePath: { eq: "icon.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    voltea: file(relativePath: { eq: "voltea-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    autoid: file(relativePath: { eq: "autoid-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    mpllogo: file(relativePath: { eq: "mpl-logotyp.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    versim: file(relativePath: { eq: "versim-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    kmt: file(relativePath: { eq: "kmt-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    euromilk: file(relativePath: { eq: "euromilk-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    prokmet: file(relativePath: { eq: "prokmet-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    azzardo: file(relativePath: { eq: "azzardo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    sgarden: file(relativePath: { eq: "sgarden.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    hairtalk: file(relativePath: { eq: "hairtalk.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    signal1: file(relativePath: { eq: "signal1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    pion: file(relativePath: { eq: "pion-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, layout: CONSTRAINED)
      }
    }
    sqm: file(relativePath: { eq: "sqm-logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    lcb: file(relativePath: { eq: "lcb.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    powerbike2: file(relativePath: { eq: "powerbike2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    farmtrac: file(relativePath: { eq: "farmtrac.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    wolff: file(relativePath: { eq: "wolff-poligrafia-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 180, layout: FULL_WIDTH)
      }
    }
    integra: file(relativePath: { eq: "integra.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    a4: file(relativePath: { eq: "a4.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    demps: file(relativePath: { eq: "demps.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    glover: file(relativePath: { eq: "glover.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    gls: file(relativePath: { eq: "gls2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    krosno: file(relativePath: { eq: "krosno.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
        frogum: file(relativePath: { eq: "frogum-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    filplast: file(relativePath: { eq: "filplast-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    agrosiec: file(relativePath: { eq: "agrosiec-maszyny.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    nespresso: file(relativePath: { eq: "nespresso-klienci.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
            lublin: file(relativePath: { eq: "targi-lublin-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
            keller: file(relativePath: { eq: "keller-logo.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
                mtb: file(relativePath: { eq: "mtb-logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
                    oknopol: file(relativePath: { eq: "okno-pol-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
            fagron: file(relativePath: { eq: "fagron-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
             dpelogo: file(relativePath: { eq: "dpe-logo-claim.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
             shimpol: file(relativePath: { eq: "shim-pol-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
              intermag: file(relativePath: { eq: "intermag-logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
              sievert: file(relativePath: { eq: "sievert-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
                  splus: file(relativePath: { eq: "s-plus-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
                   meprozet: file(relativePath: { eq: "meprozet-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 180, layout: FULL_WIDTH)
      }
    }
                       enpoll: file(relativePath: { eq: "enpol-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
  }
`;

const Dlapartnera = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Kanał partnerski"
      headline="Rozbuduj ofertę z Questy"
    >
      <SEO
        title="Dla Partnera - zostań naszym partnerem!"
        description="Dla partnera ✅ współpraca partnerska jest idealnym rozwiązaniem dla firm szukających rozwoju • Questy - Twój biznes wygrywa"
        keywords={["dla partnera"]}
      />
      <p>
        Tak samo jak w synergię talentu, wierzymy w synergię firm.{" "}
        <strong>
          Dlatego wszystkim poszukującym rozwoju i nowych kanałów biznesowych
          proponujemy współpracę partnerską
        </strong>
        . Jasne zasady, swoboda działania i sprawna komunikacja gwarantują
        korzyści dla obu stron.
      </p>
      <p>
        Program partnerski dotyczy naszych rozwiązań biznesowych:{" "}
        <strong>
          <a href="https://aurabusiness.pl/"> Aura Business</a>,
          <a href="https://synergiuscrm.pl/"> Synergius CRM</a>,
          <a href="https://automicob2b.pl/"> Automico B2B</a>,
          <a href="https://serwisplanner.pl/"> Serwis Planner</a>.{" "}
        </strong>
      </p>
      <p style={{
          paddingBottom: 60, }}> 
        <strong>Cenimy wiedzę i doświadczenie naszych Partnerów</strong>.
        Szanujemy też Państwa czas, dlatego proponujemy kilka wariantów
        współpracy:
      </p>
      <Columns>
        <div>
          <center>
            <FaUserPlus size="50px" />
            <h2> PARTNER HANDLOWY </h2>

            <p>
              Podstawowa forma partnerstwa obejmująca współpracę w zakresie
              polecania lub pozyskiwania nowych klientów, których następnie
              obsługuje Questy.
            </p>
          </center>
        </div>
        <div>
          <center>
            <FaLaptop size="50px" />
            <h2> PARTNER WDROŻENIOWY </h2>
            <p>
              Zaawansowana forma partnerstwa, w której partner zarówno pozyskuje
              klienta jak i przeprowadza wdrożenie. Questy dostarcza
              merytoryczne wsparcie.
            </p>
          </center>
        </div>
      </Columns>
      <br />
      <br />
      <br />

      <Container className={styles.wrapper}>
        <div className={styles.formWrapper}>
          <div className={styles.contactHeader}>
            <FaFile size="50px" color="#86BC2A" />
            <p>
              <strong>
                Chcesz poznać szczegóły? <br />
                Skontaktuj się z nami
              </strong>
            </p>
          </div>
          <ContactForm systemType="questy" />
        </div>
        <div className={styles.mapContainer}>
          <div className={styles.contactHeader}>
            <FaPhone size="50px" color="#86BC2A" />
            <h3>
              <strong>Michał Szymczak</strong>
            </h3>
            <p>Współpraca partnerska </p>
            <p>
              <a
                className={styles.phoneNum}
                href="tel:+48512091034"
                title="+48512091034"
              >
                +48 512 091 034
              </a>
            </p>
            <p>
              <a
                className={styles.phoneNum}
                href="mailto:michal.szymczak@questy.pl"
              >
                michal.szymczak@questy.pl
              </a>
            </p>
            <br />
            <br />

            <FaUsers size="70px" color="#86BC2A" />
            <h3>Jestem partnerem</h3>
            <br />
            <p>
              Aktualnych partnerów zapraszamy do kontaktu z nami przez panel
              partnera
            </p>

            <div
              style={{
                textAlign: "center",
                margin: "20px",
                padding: "20px",
              }}
            >
              <a className={styles.startNow} href="https://serwis.questy.pl/">
                ZALOGUJ SIĘ
              </a>
            </div>
          </div>
        </div>
      </Container>
      <Container>
        <h2  style={{
            paddingTop: 50,
             paddingBottom: 50,
          }}> Questy w liczbach</h2>
        <BenefitBlockWrapper>
          <BenefitBlock
            icon={<FaCalendarDay size="70px" color="#86BC2A" />}
            title=" Od 2002 roku wspieramy&nbsp;klientów&nbsp;biznesowych"
          />
          <BenefitBlock
            icon={<FaRegClipboard size="70px" color="#86BC2A" />}
            title="Ponad 1&nbsp;000 zrealizowanych&nbsp;projektów"
          />
          <BenefitBlock
            imga={
              <GatsbyImage
                image={data.pikselomania.childImageSharp.gatsbyImageData}
              />
            }
            icon={<FaUserTie size="70px" color="#86BC2A" />}
            title="Przeszło 50 pasjonatów&nbsp;technologii&nbsp;w&nbsp;zespole"
          />
          <BenefitBlock
            icon={<FaLaptop size="70px" color="#86BC2A" />}
            title="Więcej niż 100 000 użytkowników&nbsp;systemów"
          />
        </BenefitBlockWrapper>
      </Container>
      <h2 style={{
            paddingTop: 60,
          }}><strong>Nasi klienci</strong></h2>
      <h2 style={{
            paddingBottom: 35,
          }}>Zaufali nam, między innymi:</h2><center></center>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
          <div>
            <a href="http://www.unilever.pl/">
              <GatsbyImage
                image={data.unilever.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://www.mtp.pl/">
              <center><GatsbyImage image={data.mtp.childImageSharp.gatsbyImageData} 
              style={{
                    width: "175px",
                  }}/> 
                  </center>
            </a>
          </div>
                    <div>
            <a href="https://gls-group.eu/PL/pl/home">
              <GatsbyImage image={data.gls.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div>
            <a href="https://filplast.pl/">
            <GatsbyImage
                image={data.filplast.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://dompelenenergii.pl/">
              <center><GatsbyImage image={data.dpelogo.childImageSharp.gatsbyImageData} 
             style={{
                    width: "185px",
                  }} /></center>
            </a>
          </div>
          
        </div>
      </div>
    </section>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
        <div>
            <a href="http://www.interhandler.pl/">
               <center><GatsbyImage
                image={data.interhandler.childImageSharp.gatsbyImageData}
              style={{
                    width: "190px",
                  }}/> </center>
            </a>
          </div>
          <div>
            <a href="http://www.kp.pl/">
              <GatsbyImage
                image={data.kompania.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://www.kospel.pl/">
              <GatsbyImage
                image={data.kospel.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://www.metalfach.com.pl/">
              <GatsbyImage image={data.metal.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div>
            <a href="http://www.dedra.pl/">
              <GatsbyImage image={data.dedra.childImageSharp.gatsbyImageData} />
            </a>
          </div>
        </div>
      </div>
    </section>

    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
        <div>
            <a href="https://modecom.com/pl/">
              <GatsbyImage
                image={data.modecom.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://www.yg-1.pl/">
             <center> <GatsbyImage image={data.yg.childImageSharp.gatsbyImageData} 
              style={{
                    width: "180px",
                  }}/></center>
            </a>
          </div>
          <div>
            <a href="https://www.agro-siec.pl/">
              <GatsbyImage
                image={data.agrosiec.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://www.zibi.pl/">
              <GatsbyImage image={data.zibi.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div>
          <a href="https://targi.lublin.pl/pl/">
              <GatsbyImage
                image={data.lublin.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          
        </div>
      </div>
    </section>

    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
        <div>
            <a href="http://www.pgnig.pl/">
              <GatsbyImage image={data.pgnig.childImageSharp.gatsbyImageData} />
            </a>
          </div>
           <div>
            <a href="https://www.nespresso.com/pl/pl/">
              <GatsbyImage
                image={data.nespresso.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
           <div>
            <a href="https://www.shim-pol.pl/">
              <center><GatsbyImage
                image={data.shimpol.childImageSharp.gatsbyImageData}
                style={{
                    width: "160px",
                  }}
              /></center>
            </a>
          </div>
          <div>
            <a href="https://azzardo.com.pl/">
              <GatsbyImage
                image={data.azzardo.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://okno-pol.pl/">
              <GatsbyImage
                image={data.oknopol.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
        </div>
      </div>
    </section>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
        <div>
          <a href="http://www.mfo.pl/">
              <GatsbyImage image={data.mfo.childImageSharp.gatsbyImageData} 
              style={{
                    width: "180px",
                  }}/>
            </a>
          </div>
          <div>
            <a href="https://petring.com.pl/pl/">
              <GatsbyImage image={data.petring.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div>
            <a href="http://www.aermec.com/">
              <GatsbyImage
                image={data.aermec.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://intermag.pl/">
              <GatsbyImage
                image={data.intermag.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://mplpower.pl/">
              <center><GatsbyImage image={data.mpllogo.childImageSharp.gatsbyImageData} 
              style={{
                    width: "170px",
                  }}/></center>
            </a>
          </div>

        </div>
      </div>
    </section>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
        <div>
            <a href="http://www.dsd.com.pl/">
              <GatsbyImage
                image={data.digital.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://euromilk.pl/pl/">
              <GatsbyImage
                image={data.euromilk.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://voltea.pl/">
              <GatsbyImage
                image={data.voltea.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://krosno.com.pl/">
              <center><GatsbyImage
                image={data.krosno.childImageSharp.gatsbyImageData}
                style={{
                    width: "190px",
                  }}
              /></center>
            </a>
          </div>
          <div>
            <a href="https://www.dilmah.pl">
              <center><GatsbyImage image={data.gourmet.childImageSharp.gatsbyImageData} 
              style={{
                    width: "170px",
                  }}/></center>
            </a>
          </div>
        
        </div>
      </div>
    </section>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
          <div>
            <a href="https://www.versim.pl/">
              <GatsbyImage
                image={data.versim.childImageSharp.gatsbyImageData}
              style={{
                    width: "185px",
                  }}/>
            </a>
          </div>
          <div>
            <a href="http://kmt.com.pl/pl/">
              <GatsbyImage image={data.kmt.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div>
            <a href="https://www.frogum.com/pl/">
              <GatsbyImage
                image={data.frogum.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://www.farmtrac.pl/">
              <GatsbyImage
                image={data.farmtrac.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://wolfftrading.pl/">
              <GatsbyImage image={data.wolff.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          
        </div>
      </div>
    </section>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
          <div>
            <a href="https://sqm.eu/">
             <center> <GatsbyImage image={data.sqm.childImageSharp.gatsbyImageData} 
              style={{
                    width: "150px",
                  }}/></center>
            </a>
          </div>
          <div>
            <a href="https://www.hydrotank.eu">
              <GatsbyImage
                image={data.hydrotank.childImageSharp.gatsbyImageData}
                style={{
                    width: "180px",
                  }}
              />
            </a>
          </div>
          <div>
            <a href="https://www.sievert.pl/">
              <GatsbyImage image={data.sievert.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div>
            <a href="http://www.glover.pl/">
              <GatsbyImage
                image={data.glover.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://pi-on.pl">
              <GatsbyImage
                image={data.pion.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          
        </div>
      </div>
    </section>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
          <div>
            <a href="https://e-keller.pl/">
              <GatsbyImage
                image={data.keller.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://mtb-polska.pl/">
              <center><GatsbyImage image={data.mtb.childImageSharp.gatsbyImageData} 
               style={{
                    width: "180px",
                  }}/> </center>
            </a>
          </div>
          <div>
            <a href="https://pl.fagron.com/">
              <GatsbyImage image={data.fagron.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div>
            <a href="https://euromasz.pl/">
               <center><GatsbyImage image={data.euromasz.childImageSharp.gatsbyImageData} 
               style={{
                    width: "160px",
                  }}/> </center>
            </a>
          </div>
          <div>
            <a href="https://s-plus.pl">
             <center> <GatsbyImage image={data.splus.childImageSharp.gatsbyImageData} 
               style={{
                    width: "110px",
                  }}/></center>
            </a>
          </div>

        </div>
      </div>
    </section>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
          <div>
            <a href="http://www.enpol.com.pl/">
             <center> <GatsbyImage image={data.enpoll.childImageSharp.gatsbyImageData} 
              style={{
                    width: "110px",
                  }}/> </center>
            </a>
          </div>
          <div>
            <a href="http://www.s-garden.pl/">
              <GatsbyImage
                image={data.sgarden.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://lcb.leszno.pl/">
             <center> <GatsbyImage image={data.lcb.childImageSharp.gatsbyImageData} 
              style={{
                    width: "150px",
                  }}/></center>
            </a>
          </div>
          <div>
            <a href="http://www.europart.net/">
              <GatsbyImage
                image={data.europart.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://www.copysystem.pl/">
              <center><GatsbyImage image={data.copy.childImageSharp.gatsbyImageData} 
              style={{
                    width: "160px",
                  }}/> </center>
            </a>
          </div>

        </div>
      </div>
    </section>

    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
        <div>
            <a href="http://www.prokmet.com/">
              <GatsbyImage
                image={data.prokmet.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
         <div>
            <a href="http://a4polska.pl/">
              <center><GatsbyImage image={data.a4.childImageSharp.gatsbyImageData} 
              style={{
                    width: "160px",
                  }}/></center>
            </a>
          </div> 
           <div>
            <a href="https://meprozet.com.pl/pl">
              <GatsbyImage image={data.meprozet.childImageSharp.gatsbyImageData} 
              style={{
                    width: "190px",
                  }}/>
            </a>
          </div>
          <div>
            <a href="http://www.pikselomania.pl/">
              <GatsbyImage
                image={data.pikselomania.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>

          <div>
            <a href="http://www.demps.com.pl/">
              <GatsbyImage image={data.demps.childImageSharp.gatsbyImageData}
              style={{
                    width: "170px",
                  }} />
            </a>
          </div>

          
        </div>
      </div>
    </section>
    </FunctionPageLayout>
  );
};

export default Dlapartnera;
